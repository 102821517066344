import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Ana Sayfa | Vitality Vault'a Hoş Geldiniz
			</title>
			<meta name={"description"} content={"Özünüze Enerji Verin"} />
			<meta property={"og:title"} content={"Ana Sayfa | Vitality Vault'a Hoş Geldiniz"} />
			<meta property={"og:description"} content={"Özünüze Enerji Verin"} />
			<meta property={"og:image"} content={"https://mobizaplay.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://mobizaplay.com/img/2964514.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://mobizaplay.com/img/2964514.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://mobizaplay.com/img/2964514.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://mobizaplay.com/img/2964514.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://mobizaplay.com/img/2964514.png"} />
			<meta name={"msapplication-TileImage"} content={"https://mobizaplay.com/img/2964514.png"} />
		</Helmet>
		<Section padding="88px 0 88px 0" min-height="100vh" quarkly-title="404-1">
			<Override slot="SectionContent" max-width="1220px" justify-content="center" />
			<Box align-items="center" display="flex" justify-content="center" flex-direction="column">
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--primary">
					404
				</Text>
				<Text
					color="--dark"
					margin="8px 0px 16px 0px"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					font="--headline1"
					letter-spacing="-0.025em"
				>
					Page not found
				</Text>
				<Text
					lg-width="80%"
					font="--lead"
					color="--dark"
					margin="0px 0px 40px 0px"
					text-align="center"
				>
					Sorry, we couldn’t find the page you’re looking for.
				</Text>
			</Box>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});